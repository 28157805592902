<br>
<br>
<br>
<div class="col-md-12">
     <p class="text-xxl-left">
     Creator : Mathrusoft Technologies Private Limited
     </p>
     <h4>Please Support Our Work</h4>          
     <!-- PayPal.me Button -->
     <a href="https://paypal.me/mathrusoft" target="_blank" class="btn btn-primary btn-lg">
          <i class="bi bi-paypal"></i> Donate via PayPal
     </a>
</div>
<br>
<br>

<div class="col-md-12">
  <p class="text-xxl-left">
  Disclaimer : 
  </p>
  <p class="text-xxl-left">
  Your privacy is important to us. We do not save or store any of your content. All calculations and processing are performed directly on your device (client-side) to ensure data privacy. For the purpose of improving our services, we collect limited user experience data, such as analytics and usage patterns, but this does not include your content or personal information. If you have any questions or concerns about how we handle your data, feel free to contact us at hello&#64;mathrusoft.com
  </p>
  <div>Mathrusoft Technologies Pvt. Ltd. <i class="bi bi-c-circle"></i> {{date | date : 'yyyy'}} </div>
</div>
<br>
<br>
<br>
<br>
<br>


<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7186521322171092"
     crossorigin="anonymous"></script>
<!-- AddUnit-vertical -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-7186521322171092"
     data-ad-slot="5715991022"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>