<div class="container mt-5">
  <h1 class="text-center">EMI Calculator</h1>
  <form class="mt-4">
      <!-- Loan Amount -->
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Loan Amount</label>
        <input 
          [(ngModel)]="loanAmount"
          name="loanAmount"
          (change)="calculateEMI()"
          type="number" class="form-control" aria-describedby="loanAmountHelp">
        <div id="loanAmountHelp" class="form-text">Enter the loan amount in the above input box.</div>
      </div>
    

      <br/>

      <!-- Interest Rate -->
      <div class="mb-3">
        <label for="interestRate" class="form-label">Interest Rate (%)</label>
        <input 
          [(ngModel)]="interestRate"
          name="interestRate"
          (change)="calculateEMI()"
          type="number" class="form-control" aria-describedby="interestRateHelp">

        <input
          type="range"
          id="interestRate"
          class="form-range"
          [(ngModel)]="interestRate"
          name="interestRate"
          min="1"
          max="99"
          step="0.01"
          (change)="calculateEMI()"
          required
        />
        <div id="interestRateHelp" class="form-text">Drag above progress to increase or decease interest rate.</div>
      </div>
      <br/>

      <!-- Loan Tenure -->
      <div class="mb-3">
        <label for="loanTenure" class="form-label">Tenure (Years)</label>
        <input 
          name="loanTenure"
          (change)="calculateEMI()"
          [(ngModel)]="loanTenure"
          type="number" class="form-control" aria-describedby="loanTenureHelp">
        <input
          type="range"
          id="loanTenure"
          class="form-range"
          [(ngModel)]="loanTenure"
          name="loanTenure"
          min="1"
          max="50"
          step="1"
          (change)="calculateEMI()"
          required
        />
        <div id="loanTenureHelp" class="form-text">Drag above progress to increase or decease loan tenure (in years).</div>
      </div>
      <br/>

      <button type="button" class="btn btn-primary" (click)="calculateEMI()">Calculate EMI</button>

  </form>
    

  <!-- EMI Result -->
  <div class="mt-5" *ngIf="emi > 0">
    <h3>EMI Details</h3>
    <p><strong>Monthly EMI:</strong> ₹ {{ emi.toFixed(2) | indianCurrencyFormatter }}</p>
    <p><strong>Total Interest:</strong> ₹ {{ totalInterest.toFixed(2)| indianCurrencyFormatter }}</p>
    <p><strong>Total Payment (Principal + Interest):</strong> ₹ {{ (totalPrincipal + totalInterest).toFixed(2) | indianCurrencyFormatter}}</p>

  </div>
</div>