<div class="container-fluid mt-5">

    <div class="row">
		<div class="col-md-4">
		</div>
		<div class="mt-5 d-flex align-items-center justify-content-center col-md-4">
            
            <br/>
            Happy Math!! Very Simple Say Correct or Wrong!!
        </div>
		<div class="col-md-4">
		</div>
	</div>
	<div class="row">
		<div class="col-md-4">
		</div>
		<div class="mt-5 d-flex align-items-center justify-content-center col-md-4">
            <h1>{{questionString}}</h1>
            <br/>
            <br/>
		</div>
		<div class="col-md-4">
		</div>
	</div>
    <div class="row">
		<div class="col-md-4">
		</div>
		<div class="d-flex align-items-center justify-content-center col-md-4">
            
		</div>
		<div class="col-md-4">
		</div>
	</div>

    @if (!showScoreResult) {
        <div class="row mt-3">
            <div class="col-md-4">
            </div>
            <div class="col-md-4">
                <div class="progress">
                    <div class="progress-bar progress-bar-animated" role="progressbar" [attr.aria-valuenow]="progress" aria-valuemin="0" aria-valuemax="100" [style.width.%]="progress">{{ progress | number: '1.0-0' }}%</div>
                  </div>
            </div>
            <div class="col-md-4">
            </div>
        </div>
   
        <div class="row mt-3">
            <div class="col-md-4">
            </div>
            <div class="col-md-12 order-md-1 gap-5 d-flex align-items-center justify-content-center col-md-4">
                <button type="button" (click)="wrongClick()" class="mr-5 btn btn-danger">Wrong</button> 
                <button type="button" (click)="corretClick()" class="btn btn-success">Correct</button>
            </div>
            <div class="mt-3 col-md-12 order-md-1 gap-3 d-flex align-items-center justify-content-center col-md-4">
                <p>You can also use Arrow keys on keyboard</p>
            </div>
            <div class="col-md-4">
            </div>
        </div>
    }

    @if (showScoreResult) {
        <div class="row mt-3">
            <div class="col-md-4">
            </div>
            <div class="d-grid gap-3 d-flex align-items-center justify-content-center col-md-4">
                {{timeOut}} You Scroed {{score}} !!
            </div>
            <div class="col-md-4">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-4">
            </div>
            <div class="d-grid gap-3 d-flex align-items-center justify-content-center col-md-4">
                <button type="button" (click)="generateEquationFirstTime()" class="btn btn-success">OK!</button>
            </div>
            <div class="col-md-4">
            </div>
        </div>
    }

   
</div>