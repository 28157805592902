<div class="container mt-2">
    <h3 class="text-center">List Compare</h3>
    <div class="row">
      <div class="col-md-6 mt-2">
        <input type="text" class="form-control" id="exampleInput" [(ngModel)]="titleA" required>
        <textarea class="form-control mt-1" id="exampleTextarea" rows="8"  [(ngModel)]="listA" placeholder="List of strings..."></textarea>
      </div>
     <div class="col-md-6 mt-2">
          <input type="text" class="form-control" id="exampleInput" [(ngModel)]="titleB" required>
          <textarea class="form-control mt-1" id="exampleTextarea" rows="8" [(ngModel)]="listB" placeholder="List of strings..."></textarea>
      </div>
    </div>
    <div class="row text-center col-md-2 mt-4 mx-auto "> <button class="btn btn-primary" (click)="onCompareClick()">Compare</button> </div>
    
    <div class="row mt-3">
      <div class="col-md-2">
        <input class="form-check-input" type="checkbox" (change)="onCompareClick()" [(ngModel)]="chekboxCaseSensitive">
        <label class="form-check-label" for="firstCheckbox">{{labelCaseSensitive}}</label>
      </div>
      <div class="col-md-2">
        <input class="form-check-input" type="checkbox" (change)="onCompareClick()" [(ngModel)]="checkboxSort">
        <label class="form-check-label" for="firstCheckbox">{{labelSort}}</label>
      </div>
      <div class="col-md-2">
        <input class="form-check-input" type="checkbox" (change)="onCompareClick()" [(ngModel)]="checkboxRemoveDuplicate">
        <label class="form-check-label" for="firstCheckbox">{{labelRemoveDuplicate}}</label>
      </div>
    </div>

  </div>


  <div [hidden]="isResultFound" class="container mt-5">
    <h3 class="text-center">Result</h3>
    <div class="row">
      <div class="col-md-3">
        <h5 class="card-title">Only <b>{{titleA}}</b></h5>
        <textarea class="form-control mt-1" id="exampleTextarea" rows="8"  [(ngModel)]="onlyInListA"></textarea>
      </div>
      <div class="col-md-3">
        <h5 class="card-title">Only <b>{{titleB}}</b></h5>
        <textarea class="form-control mt-1" id="exampleTextarea" rows="8"  [(ngModel)]="onlyInListB"></textarea>
      </div>
      <div class="col-md-3">
        <h5 class="card-title"><b>{{titleA}}</b> (and | U) <b>{{titleB}}</b></h5>
        <textarea class="form-control mt-1" id="exampleTextarea" rows="8"  [(ngModel)]="onListAandB"></textarea>
      </div>
      <div class="col-md-3">
        <h5 class="card-title"><b>{{titleA}}</b> (or | ∩) <b>{{titleB}}</b></h5>
        <textarea class="form-control mt-1" id="exampleTextarea" rows="8"  [(ngModel)]="onListAorB"></textarea>
      </div>
      
    </div>
  </div>


  <div class="mt-5 accordion" id="accordionPanelsStayOpenExample">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
          How Tool works
        </button>
      </h2>
      <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show">
        <div class="accordion-body">
          <p>We can break the comparison of two lists into four parts:</p>
          <ol>
              <li>
                <h3>Items Only in ListA</h3>
                <p>These are the elements that are present in <strong>ListA</strong> but not in <strong>ListB</strong>.</p>
                <h3>Steps</h3>
                <p>Subtract the elements of <strong>ListB</strong> from <strong>ListA</strong>.</p>
                <h3>Example</h3>
                <ul>
                    <li><strong>ListA</strong>: <code>["apple", "banana", "mango"]</code></li>
                    <li><strong>ListB</strong>: <code>["banana", "mango", "pineapple", "Grapes"]</code></li>
                </ul>
                <p><strong>Unique items in ListA:</strong> <code>["apple"]</code></p>
              </li>
              <li>
                
                <h3>Items Only in ListB</h3>
                <p>These are the elements that are present in <strong>ListB</strong> but not in <strong>ListA</strong>.</p>

                <h3>Steps</h3>
                <p>Subtract the elements of <strong>ListA</strong> from <strong>ListB</strong>.</p>

                <h3>Example</h3>
                <p><strong>Unique items in ListB:</strong> <code>["pineapple", "Grapes"]</code></p>


              </li>
              <li>
                <h3>Items in Both ListA and ListB</h3>
                <p>These are the elements that exist in both <strong>ListA</strong> and <strong>ListB</strong> (intersection).</p>
                
                <h3>Steps</h3>
                <p>Find the common elements in both lists.</p>
                
                <h3>Example</h3>
                <p><strong>Common items:</strong> <code>["banana", "mango"]</code></p>                
              </li>
              <li>
                <h3>Items in Either ListA or ListB</h3>
                <p>These are the elements that exist in at least one of the lists (<strong>union</strong>).</p>

                <h3>Steps</h3>
                <p>Combine all unique elements from both lists.</p>

                <h3>Example</h3>
                <p><strong>Union of ListA and ListB:</strong> <code>["apple", "banana", "mango", "pineapple", "Grapes"]</code></p>        
              </li>
          </ol>


        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
          How "{{labelCaseSensitive}}" works
        </button>
      </h2>
      <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
        <div class="accordion-body">

          <h3>Input Data</h3>
          <p><strong>ListA:</strong> <code>["Apple", "apple", "banana", "mango", "grapes", "apple"]</code></p>
          <p><strong>ListB:</strong> <code>["banana", "mango", "Pineapple", "Grapes", "mango"]</code></p>

          <h3>Items Only in ListA (Case Sensitive)</h3>
          <p>These are the elements that are present in <strong>ListA</strong> but not in <strong>ListB</strong>, considering case sensitivity.</p>
          <h3>Example</h3>
          <p><strong>Unique items in ListA:</strong> <code>["Apple", "apple", "grapes", "apple"]</code></p>

          <h3>Items Only in ListB (Case Sensitive)</h3>
          <p>These are the elements that are present in <strong>ListB</strong> but not in <strong>ListA</strong>, considering case sensitivity.</p>
          <h3>Example</h3>
          <p><strong>Unique items in ListB:</strong> <code>["Pineapple", "Grapes"]</code></p>

          <h3>Items in Both ListA and ListB (Intersection) (Case Sensitive)</h3>
          <p>These are the elements that exist in both <strong>ListA</strong> and <strong>ListB</strong>, considering case sensitivity.</p>
          <h3>Example</h3>
          <p><strong>Common items:</strong> <code>["banana", "mango"]</code></p>

          <h3>Items in Either ListA or ListB (Union) (Case Sensitive)</h3>
          <p>These are the unique elements that exist in at least one of the lists, considering case sensitivity.</p>
          <h3>Example</h3>
          <p><strong>Union of ListA and ListB:</strong> <code>["Apple", "apple", "banana", "mango", "grapes", "Pineapple", "Grapes"]</code></p>


          <h3>Items Only in ListA (Case Insensitive)</h3>
          <p>These are the elements that are present in <strong>ListA</strong> but not in <strong>ListB</strong>, considering case insensitivity.</p>
          <h3>Example</h3>
          <p><strong>Unique items in ListA:</strong> <code>["apple", "apple", "apple"]</code></p>

          <h3>Items Only in ListB (Case Insensitive)</h3>
          <p>These are the elements that are present in <strong>ListB</strong> but not in <strong>ListA</strong>, considering case insensitivity.</p>
          <h3>Example</h3>
          <p><strong>Unique items in ListB:</strong> <code>["pineapple"]</code></p>

          <h3>Items in Both ListA and ListB (Intersection) (Case Insensitive)</h3>
          <p>These are the elements that exist in both <strong>ListA</strong> and <strong>ListB</strong>, considering case insensitivity.</p>
          <h3>Example</h3>
          <p><strong>Common items:</strong> <code>["banana", "mango", "grapes"]</code></p>

          <h3>Items in Either ListA or ListB (Union) (Case Insensitive)</h3>
          <p>These are the unique elements that exist in at least one of the lists, considering case insensitivity.</p>
          <h3>Example</h3>
          <p><strong>Union of ListA and ListB:</strong> <code>["apple", "banana", "mango", "grapes", "pineapple"]</code></p>

        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
          How "{{labelSort}}" works
        </button>
      </h2>
      <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
        <div class="accordion-body">
          <h3>Input Data</h3>
          <p><strong>ListA:</strong> <code>["Apple", "apple", "banana", "mango", "grapes", "apple"]</code></p>
          <p><strong>ListB:</strong> <code>["banana", "mango", "Pineapple", "Grapes", "mango"]</code></p>
          
          <h3>Items Only in ListA (Case Sensitive and Sorted)</h3>
          <p>These are the elements that are present in <strong>ListA</strong> but not in <strong>ListB</strong>, considering case insensitivity and sorted alphabetically.</p>
          <h3>Example</h3>
          <p><strong>Unique items in ListA:</strong> <code>["apple", "apple", "apple", "grapes"]</code></p>
          
          <h3>Items Only in ListB (Case Sensitive and Sorted)</h3>
          <p>These are the elements that are present in <strong>ListB</strong> but not in <strong>ListA</strong>, considering case insensitivity and sorted alphabetically.</p>
          <h3>Example</h3>
          <p><strong>Unique items in ListB:</strong> <code>["Grapes", "Pineapple"]</code></p>
          
          <h3>Items in Both ListA and ListB (Intersection) (Case Sensitive and Sorted)</h3>
          <p>These are the elements that exist in both <strong>ListA</strong> and <strong>ListB</strong>, considering case insensitivity and sorted alphabetically.</p>
          <h3>Example</h3>
          <p><strong>Common items:</strong> <code>["banana", "mango"]</code></p>
          
          <h3>Items in Either ListA or ListB (Union) (Case Sensitive and Sorted)</h3>
          <p>These are the unique elements that exist in at least one of the lists, considering case insensitivity and sorted alphabetically.</p>
          <h3>Example</h3>
          <p><strong>Union of ListA and ListB:</strong> <code>["Apple", "apple", "banana", "grapes", "mango", "Pineapple", "Grapes"]</code></p>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
          How "{{labelRemoveDuplicate}}" works
        </button>
      </h2>
      <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse">
        <div class="accordion-body">
          <h3>Input Data</h3>
          <p><strong>ListA:</strong> <code>["Apple", "apple", "banana", "mango", "grapes", "apple"]</code></p>
          <p><strong>ListB:</strong> <code>["banana", "mango", "Pineapple", "Grapes", "mango"]</code></p>

          <h3>Items Only in ListA (Case Sensitive and Remove Duplicates)</h3>
          <p>These are the elements that are present in <strong>ListA</strong> but not in <strong>ListB</strong>, considering case insensitivity and with duplicates removed.</p>
          <h3>Example</h3>
          <p><strong>Unique items in ListA:</strong> <code>["Apple", "apple", "grapes"]</code></p>

          <h3>Items Only in ListB (Case Sensitive and Remove Duplicates)</h3>
          <p>These are the elements that are present in <strong>ListB</strong> but not in <strong>ListA</strong>, considering case insensitivity and with duplicates removed.</p>
          <h3>Example</h3>
          <p><strong>Unique items in ListB:</strong> <code>["Pineapple", "Grapes"]</code></p>

          <h3>Items in Both ListA and ListB (Intersection) (Case Sensitive and Remove Duplicates)</h3>
          <p>These are the elements that exist in both <strong>ListA</strong> and <strong>ListB</strong>, considering case insensitivity and with duplicates removed.</p>
          <h3>Example</h3>
          <p><strong>Common items:</strong> <code>["banana", "mango"]</code></p>

          <h3>Items in Either ListA or ListB (Union) (Case Sensitive and Remove Duplicates)</h3>
          <p>These are the unique elements that exist in at least one of the lists, considering case insensitivity and with duplicates removed.</p>
          <h3>Example</h3>
          <p><strong>Union of ListA and ListB:</strong> <code>["Apple", "apple", "banana", "mango", "grapes", "Pineapple", "Grapes"]</code></p>

        </div>
      </div>
    </div>
  </div>