import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mathbuzz',
  templateUrl: './mathbuzz.component.html',
  styleUrl: './mathbuzz.component.css'
})
export class MathbuzzComponent implements OnInit, OnDestroy {

  titleText = 'MathBuzz | maTools'
  descriptionText = 'Online Game for Childred to improve spontaniours responsing using Maths!! It helps childred improve there IQ and decision making skills!!'
  url = 'https://matools.org/' + 'math-buzz'
  siteName = 'maTools.org'
  twitterAccId = '@mathrusoft'
  keyWords = 'Math, Children, Children game, Online game, Number, Positive, Equation, IQ Game, Decision Game, Maths, Mathematics'

  constructor(private meta: Meta,
    private title: Title,
    private route: ActivatedRoute,
    private analytics: AngularFireAnalytics) {

      this.meta.updateTag({ name: 'robots', content: `noodp,index,follow,all`});
      this.title.setTitle(`${this.titleText}`);
      this.meta.updateTag({ name: 'description', content: `${this.descriptionText}`});
      this.meta.updateTag({ name: 'keywords', content: `${this.keyWords}`});

      this.meta.updateTag({ name: 'og:type', content: `website`});
      this.meta.updateTag({ name: 'og:url', content: `${this.url}`});
      this.meta.updateTag({ name: 'og:title', content: `${this.titleText}`});
      this.meta.updateTag({ name: 'og:description', content: `${this.descriptionText}`});
      this.meta.updateTag({ name: 'og:site_name', content: `${this.siteName}`});

      this.meta.updateTag({ name: 'twitter:site', content: `${this.twitterAccId}`});
      this.meta.updateTag({ name: 'twitter:account_id', content: `${this.titleText}`});
      this.meta.updateTag({ name: 'twitter:title', content: `${this.titleText}`});
      this.meta.updateTag({ name: 'twitter:description', content: `${this.descriptionText}`});

      this.logPageView();

      this.generateEquationFirstTime()
  }

  @HostListener('window:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowLeft':
        this.wrongClick();
        break;
      case 'ArrowRight':
        this.corretClick();
        break;
      case 'ArrowUp':
        this.corretClick();
        break;
      case 'ArrowDown':
        this.wrongClick();
        break;
      default:
        break;
    }
  }

  logPageView() {
    this.analytics.logEvent('page_view', { page_path: '/' });
  }

  totalTime: number = 6; // Default total time
  progress: number = 0;
  timerInterval: any; // To store the interval ID

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.timerInterval) {
      clearInterval(this.timerInterval); // Clear interval to prevent memory leaks
    }
  }

  startTimer() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval); // Clear interval to prevent memory leaks
    }
    this.progress = 0; // Reset progress
    const interval = 1000;

    this.timerInterval = setInterval(() => {
      this.progress += (100 / this.totalTime);

      if (this.progress >= 100) {
        this.progress = 100;
        this.timeOut = "Oops Time Out! "
        this.showScore()
      }
    }, interval);
  }

  a = 9
  b = 10
  a_b = 21
  result = true
  score = 0
  showScoreResult = false
  timeOut = ""

  questionString = ""
  generateEquation() {
    this.showScoreResult = false
    this.timeOut = ""
    this.score++
    this.startTimer()
    this.a = this.getA()
    this.b = this.getB()
    if(this.getRandomBoolean()) {
      this.result = true
      this.a_b = this.a + this.b
    } else {
      this.result = false
      this.a_b = this.getWrong(this.a, this.b)
    }
    let newQuestion = `${this.a} + ${this.b} = ${this.a_b}`
    if(newQuestion == this.questionString ) {
      this.generateEquation()
      return
    }
    this.questionString = newQuestion
  }

  generateEquationFirstTime() {
    this.score = 0
    if (this.timerInterval) {
      clearInterval(this.timerInterval); // Clear interval to prevent memory leaks
    }
    this.progress = 0
    this.showScoreResult = false
    this.timeOut = ""
    this.a = this.getA()
    this.b = this.getB()
    if(this.getRandomBoolean()) {
      this.result = true
      this.a_b = this.a + this.b
    } else {
      this.result = false
      this.a_b = this.getWrong(this.a, this.b)
    }
    let newQuestion = `${this.a} + ${this.b} = ${this.a_b}`
    if(newQuestion == this.questionString ) {
      this.generateEquation()
      return
    }
    this.questionString = newQuestion
  }

  wrongClick() {
    if(!this.result) {
      return this.generateEquation()
    }
    if (this.timerInterval) {
      clearInterval(this.timerInterval); // Clear interval to prevent memory leaks
    }
    this.timeOut = "You said Wrong for this! "
    this.showScore()
  }

  corretClick() {
    if(this.result) {
      return this.generateEquation()
    }
    if (this.timerInterval) {
      clearInterval(this.timerInterval); // Clear interval to prevent memory leaks
    }
    this.timeOut = "You said Correct for this! "
    this.showScore()
  }

  showScore() {
    this.showScoreResult = true
  }

  getA(): number {
    let min = 1
    let max = 3

    if(this.score > 5) {
      min = 2 
      max = 4
    }

    if(this.score > 10) {
      min = 3
      max = 5
    }

    if(this.score > 30) {
      min = 4 
      max = 6
    }

    if(this.score > 60) {
      this.totalTime = 5
      min = 5 
      max = 7
    }


    if(this.score > 100) {
      this.totalTime = 4
      min = 3 
      max = 6
    }

    if(this.score > 120) {
      min = 4 
      max = 7
    }


    if(this.score > 150) {
      min = 5 
      max = 8
    }

    if(this.score > 200) {
      this.totalTime = 3
      min = 6 
      max = 9
    }


    if(this.score > 240) {
      this.totalTime = 3
      min = 7 
      max = 10
    }

    if (min > max) {
      let t = min
      min = max
      max = t
    }
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


  getB(): number {
    let min = 1
    let max = 3

    if(this.score > 5) {
      min = 2 
      max = 4
    }

    if(this.score > 10) {
      min = 3
      max = 5
    }

    if(this.score > 30) {
      min = 4 
      max = 6
    }

    if(this.score > 60) {
      this.totalTime = 5
      min = 5 
      max = 7
    }


    if(this.score > 100) {
      this.totalTime = 4
      min = 3 
      max = 6
    }

    if(this.score > 120) {
      min = 4 
      max = 7
    }


    if(this.score > 150) {
      min = 5 
      max = 8
    }

    if(this.score > 200) {
      this.totalTime = 3
      min = 6 
      max = 9
    }


    if(this.score > 240) {
      this.totalTime = 3
      min = 7 
      max = 10
    }

    if (min > max) {
      let t = min
      min = max
      max = t
    }
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getWrong(a:number, b:number): number {
    if(this.getRandomBoolean()) {
      return (a + b) - 1
    } else {
      return (a + b) + 1
    }
  }

  getRandomBoolean(): boolean {
    return Math.random() < 0.5;
  }

}
