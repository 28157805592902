<div class="container mt-2">
    <h3 class="text-center">Word Count</h3>
    <div class="row ">
      <div class="row col-md-10 text-center mt-4 mx-auto "> 
        <textarea class="form-control mt-1" id="exampleTextarea" rows="8"  [(ngModel)]="listA" placeholder="List of strings..."></textarea>
      </div>
    </div>
    <div class="row col-md-2 text-center mt-4 mx-auto "> 
      <button class="btn btn-primary" (click)="onCompareClick()">Count</button> 
    </div>
    
    <div class="row col-md-10 text-left mt-4 mx-auto ">
      <div class="col-md-4">
        <input class="form-check-input" type="checkbox" (change)="onCompareClick()" [(ngModel)]="checkboxSplitBySpace">
        <label class="form-check-label" for="firstCheckbox">{{labelSplitBySpace}}</label>
      </div>
      <div class="col-md-4">
        <input class="form-check-input" type="checkbox" (change)="onCompareClick()" [(ngModel)]="checkboxSplitByComma">
        <label class="form-check-label" for="firstCheckbox">Split words by <b>, (Comma)</b></label>
      </div>
      <div class="col-md-4">
        <input class="form-check-input" type="checkbox" (change)="onCompareClick()" [(ngModel)]="chekboxCaseInSensitive">
        <label class="form-check-label" for="firstCheckbox">{{labelCaseInSensitive}}</label>
      </div>
    </div>


  <div class="row col-md-10 text-left mt-4 mx-auto ">
    <div class="col-md-4">
      <input class="form-check-input" type="checkbox" (change)="onCompareClick()" [(ngModel)]="checkboxRemoveDuplicate">
      <label class="form-check-label" for="firstCheckbox">{{labelRemoveDuplicate}}</label>
    </div>
    <div class="col-md-4">
      <input class="form-check-input" type="checkbox" (change)="onCompareClick()" [(ngModel)]="checkboxSort">
      <label class="form-check-label" for="firstCheckbox">{{labelSort}}</label>
    </div>
  </div>

  <div [hidden]="isResultFound" class="container mt-5">
    <h3 class="text-center">Result</h3>
    <div class="row">
      <div class="row col-md-10 text-center mt-4 mx-auto "> 
        <textarea class="form-control mt-1" id="exampleTextarea" rows="8"  [(ngModel)]="result"></textarea>
      </div>
    </div>
  </div>





</div>

<div class="accordion-item">
  <h2 class="accordion-header">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
      How Tool works
    </button>
  </h2>
  <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse">
    <div class="accordion-body">

      <h3>Input Data</h3>
      <p><strong>ListA:</strong> <code>["Apple", "apple", "banana", "mango", "grapes", "apple", "pineapple", "banana"]</code></p>

      <h3>Word Count (Case Sensitive)</h3>
      <p>This is the count of each unique word in <strong>ListA</strong>, considering case sensitivity</p>
      <h3>Result</h3>
      <ul>
          <li><strong>Apple:</strong> 1</li>
          <li><strong>apple:</strong> 2</li>
          <li><strong>banana:</strong> 2</li>
          <li><strong>mango:</strong> 1</li>
          <li><strong>grapes:</strong> 1</li>
          <li><strong>pineapple:</strong> 1</li>
          <li><strong>Pineapple:</strong> 1</li>
          <li><strong>Grapes:</strong> 1</li>
      </ul>


    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
      How {{labelSplitBySpace}} works
    </button>
  </h2>
  <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
    <div class="accordion-body">

      <h3>Input Data</h3>
      <p><strong>ListA:</strong> <code> Apple apple banana mango grapes apple pineapple banana mango Pineapple Grapes mango</code></p>
      
      <h3>Word Count (Case Sensitive)</h3>
      <p>This is the count of each unique word in <strong>ListA</strong>, split by <code>" " (space)</code></p>
      <h3>Result</h3>
      <ul>
          <li><strong>Apple:</strong> 1</li>
          <li><strong>apple:</strong> 2</li>
          <li><strong>banana:</strong> 2</li>
          <li><strong>mango:</strong> 3</li>
          <li><strong>grapes:</strong> 1</li>
          <li><strong>pineapple:</strong> 1</li>
          <li><strong>Pineapple:</strong> 1</li>
          <li><strong>Grapes:</strong> 1</li>
      </ul>
      

    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
      How Split words by <span> <code> `,` (Comma) </code> </span> works
    </button>
  </h2>
  <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
    <div class="accordion-body">

      <h3>Input Data</h3>
      <p><strong>ListA:</strong> <code> Apple, apple,banana, mango,grapes, apple,pineapple, banana, mango, Pineapple, Grapes, mango</code></p>

      <h3>Word Count (Case Sensitive)</h3>
      <p>This is the count of each unique word in <strong>ListA</strong>, split by commas (`,`), and considering case sensitivity.</p>
      <h3>Result</h3>
      <ul>
          <li><strong>Apple:</strong> 1</li>
          <li><strong>apple:</strong> 2</li>
          <li><strong>banana:</strong> 2</li>
          <li><strong>mango:</strong> 3</li>
          <li><strong>grapes:</strong> 1</li>
          <li><strong>pineapple:</strong> 1</li>
          <li><strong>Pineapple:</strong> 1</li>
          <li><strong>Grapes:</strong> 1</li>
      </ul>


    </div>
  </div>
</div>


<div class="accordion-item">
  <h2 class="accordion-header">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
      How Ignore Case (Convert to Lower case) works
    </button>
  </h2>
  <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse">
    <div class="accordion-body">

      <h3>Input Data</h3>
      <p><strong>ListA: </strong> <code>["Apple", "apple", "banana", "mango", "grapes", "apple", "pineapple", "banana"]</code></p>

      <h3>Word Count Ignore Case (Convert to Lower case)</h3>
      <p>This is the count of each unique word in <strong>ListA</strong>, split by new lines and ignoring case.</p>
      <h3>Result</h3>
      <ul>
          <li><strong>apple:</strong> 3</li>
          <li><strong>banana:</strong> 2</li>
          <li><strong>mango:</strong> 1</li>
          <li><strong>grapes:</strong> 1</li>
          <li><strong>pineapple:</strong> 1</li>
      </ul>



    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
      How {{labelRemoveDuplicate}} works
    </button>
  </h2>
  <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse">
    <div class="accordion-body">
      <h3>Input Data</h3>
      <p><strong>ListA: </strong> <code>["Apple", "apple", "banana", "mango", "grapes", "apple", "pineapple", "banana"]</code></p>
      
      <h3>Result (Remove Duplicates - Case Sensitive)</h3>
      <p>This is the unique list of words from <strong>ListA</strong>, retaining their original cases and removing duplicates.</p>
      <h3>Result</h3>
      <ul>
          <li>Apple</li>
          <li>apple</li>
          <li>banana</li>
          <li>mango</li>
          <li>grapes</li>
          <li>pineapple</li>
      </ul>
      
    </div>
  </div>
</div>

<div class="accordion-item">
  <h2 class="accordion-header">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
      How {{labelSort}} works
    </button>
  </h2>
  <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse">
    <div class="accordion-body">
      <h3>Input Data</h3>
      <p><strong>ListA: </strong> <code>["Apple", "apple", "banana", "mango", "grapes", "apple", "pineapple", "banana"]</code></p>

      <h3>Word Count (Case Sensitive and Sorted)</h3>
      <p>This is the count of each unique word in <strong>ListA</strong>, considering case sensitivity and sorting the result alphabetically.</p>
      <h3>Result</h3>
      <ul>
          <li><strong>Apple:</strong> 1</li>
          <li><strong>Grapes:</strong> 1</li>
          <li><strong>Pineapple:</strong> 1</li>
          <li><strong>apple:</strong> 2</li>
          <li><strong>banana:</strong> 2</li>
          <li><strong>grapes:</strong> 1</li>
          <li><strong>mango:</strong> 3</li>
          <li><strong>pineapple:</strong> 1</li>
      </ul>

      
    </div>
  </div>
</div>

