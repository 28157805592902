
<div class="row">
    <div class="col-md-8">
        <h3>{{inputUUIDData?.title}} UUID Generator</h3>
        <br>
        <div class="d-flex align-items-center">
          <!-- Paragraph displaying the UUID -->
          <p class=" mb-0 me-2">{{ inputUUIDData?.topUUID }}</p>
            <!-- Show 'Copied' message for a few seconds -->

          <!-- Copy button with Bootstrap styling -->
          <button class="btn btn-primary" 
          data-bs-toggle="tooltip" 
          data-bs-placement="top" 
          title="Copy to clipboad"
          (click)="copyToClipboard(inputUUIDData!.topUUID)">
            <i class="bi bi-clipboard"></i> Copy
          </button>
          @if (copiedMessage) {
            <div class="text-success mt-2">
               {{ copiedMessage }}
            </div>
          }
        </div>
      
        <button class="btn btn-link mt-2" type="button" (click)="generateNewTopUUID()">
          Generate New UUID
        </button>
    </div>
</div>


<div class="row mt-5">
  <div class="mt-5 col-md-8">

    <h3>Bulk {{inputUUIDData?.title}} UUID Generation</h3>
    <div class="mt-2 d-flex align-items-top">
      <label for="numberInput" class="form-label me-2">How Many?</label>
      <input 
        type="number" 
        class="form-control w-auto  " 
        id="numberInput" 
        placeholder="Enter a value" 

        [(ngModel)]="positiveNumber" 
        (input)="validatePositiveNumber($event)" 
        (keypress)="allowOnlyNumbers($event)"
      />

      <button class="btn btn-primary" (click)="generateBulkUUID()">
        <span class="d-none d-md-inline">Generate </span>
         <i class="bi bi-arrow-right-circle-fill"></i>
      </button>
    </div>

    @if (resultList) {

      <div class="row mt-5">
        <div class="col-md-8">

          <div class="d-flex align-items-center">

            <p class=" mb-0 me-2">{{inputUUIDData?.title}}, Generated </p>
            <button 
            class="btn btn-primary"
            (click)="copyToClipboard(resultList, true)">
              <i class="bi bi-clipboard"></i> Copy
            </button>
            @if (bulkUUIDcopiedMessage) {
              <div class="text-success mt-2">
                {{ bulkUUIDcopiedMessage }}
              </div>
            }
          </div>

          <textarea 
            class="form-control" 
            id="copyTextarea" 
            rows="15" 
            [(ngModel)]="resultList"
            placeholder="Result will be here..."
          ></textarea>
        </div>
      </div>  
    }
  </div>

</div>

<br>
<br>
<h2>Disclaimer</h2>
<p><strong>Important Note:</strong></p>
<p>
    While our tool is designed to generate UUIDs (Universally Unique Identifiers) online for free, we 
    <strong>do not guarantee</strong> the uniqueness of the generated UUIDs. The process relies on standard 
    algorithms for UUID generation, which typically produce unique identifiers, but there is a 
    <strong>small chance of collision</strong> (duplicate UUIDs).
</p>
<p>
    We strongly recommend that you <strong>verify the uniqueness</strong> of each generated UUID on your 
    end before using it in critical systems or applications. The responsibility for ensuring the uniqueness 
    of UUIDs rests with the user.
</p>



    <script>
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })
    </script>