<div>
  <!-- Tabs -->
   <h1>Online UUID Generator</h1>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="v1-tab" data-bs-toggle="tab" data-bs-target="#v1" type="button" role="tab" aria-controls="v1" aria-selected="true">
        Version V1
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="v4-tab" data-bs-toggle="tab" data-bs-target="#v4" type="button" role="tab" aria-controls="v4" aria-selected="false">
        Version V4
      </button>
    </li>
    <!-- <li class="nav-item" role="presentation">
      <button class="nav-link" id="v5-tab" data-bs-toggle="tab" data-bs-target="#v5" type="button" role="tab" aria-controls="v5" aria-selected="false">
        Version V5
      </button>
    </li> -->
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="v6-tab" data-bs-toggle="tab" data-bs-target="#v6" type="button" role="tab" aria-controls="v6" aria-selected="false">
        Version V6
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="v7-tab" data-bs-toggle="tab" data-bs-target="#v7" type="button" role="tab" aria-controls="v7" aria-selected="false">
        Version V7
      </button>
    </li>
  </ul>

  <!-- Tab Content -->
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="v1" role="tabpanel" aria-labelledby="v1-tab">
      <app-generate-uuid [(inputUUIDData)]="v1uuid"/>
    </div>
    <div class="tab-pane fade" id="v4" role="tabpanel" aria-labelledby="v4-tab">
      <app-generate-uuid [(inputUUIDData)]="v4uuid"/>
    </div>
    <!-- <div class="tab-pane fade" id="v5" role="tabpanel" aria-labelledby="v5-tab">
      <app-generate-uuid [(inputUUIDData)]="v5uuid"/>
    </div> -->
    <div class="tab-pane fade" id="v6" role="tabpanel" aria-labelledby="v6-tab">
      <app-generate-uuid [(inputUUIDData)]="v6uuid"/>
    </div>
    <div class="tab-pane fade" id="v7" role="tabpanel" aria-labelledby="v7-tab">
      <app-generate-uuid [(inputUUIDData)]="v7uuid"/>
    </div>
  </div>
</div>
