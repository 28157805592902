import { Routes } from '@angular/router';
import { WordCountComponent } from './tool/word-count/word-count.component';
import { ListCompareComponent } from './tool/list-compare/list-compare.component';
import { UuidComponent } from './tool/uuid/uuid.component';
import { QrCodeGeneratorComponent } from './tool/qr-code/qr-code-generator/qr-code-generator.component';
import { EmiCalculatorComponent } from './tool/emi-calculator/emi-calculator.component';
import { RentReceiptComponent } from './tool/rent-receipt/rent-receipt.component';
import { MathbuzzComponent } from './tool/mathbuzz/mathbuzz.component';
import { MultiQrGeneratorComponent } from './tool/qr-code/multi-qr-generator/multi-qr-generator.component';

export const routes: Routes = [{
        path:"", // <yourdomain>/
        component:MathbuzzComponent
    },
    {
        path:"compare-list", // <yourdomain>/compare-list
        component:ListCompareComponent
    },
    {
        path:'word-count', // <yourdomain>/word-count
        component: WordCountComponent
    },
    {
        path:'generate-uuid', // <yourdomain>/generate-uuid
        component: UuidComponent
    },
    {
        path:'generate-qr-code', // <yourdomain>/generate-qr-code
        component: QrCodeGeneratorComponent
    },
    {
        path:'generate-multi-qr-code', // <yourdomain>/generate-qr-code
        component: MultiQrGeneratorComponent
    },
    {
        path:'generate-rent-receipt', // <yourdomain>/generate-qr-code
        component: RentReceiptComponent
    },
    {
        path:'rent-receipt', // <yourdomain>/generate-qr-code
        component: RentReceiptComponent
    },
    {
        path:'emi-calculator', // <yourdomain>/app-emi-calculator
        component: EmiCalculatorComponent
    },
    {
        path:'math-buzz', 
        component: MathbuzzComponent
    },
    { path: '**', component: MathbuzzComponent }
];
