import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { WordCountComponent } from "./tool/word-count/word-count.component";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { environment } from '../environments/environment';
import { AngularFireModule } from "@angular/fire/compat";
import { ActionBarComponent } from "./header/action-bar/action-bar.component";
import { RouterLink, RouterOutlet } from "@angular/router";
import { SortComponent } from "./tool/sort/sort.component";
import { AppRoutingModule } from "./app-routing.module";
import { ListCompareComponent } from "./tool/list-compare/list-compare.component";
import { FooterComponent } from "./footer/footer.component";
import { UuidComponent } from "./tool/uuid/uuid.component";
import { GenerateUuidComponent } from "./tool/uuid/generate-uuid/generate-uuid.component";
import { QRCodeModule } from "angularx-qrcode";
import { QrCodeGeneratorComponent } from "./tool/qr-code/qr-code-generator/qr-code-generator.component";
import { JsonViewerComponent } from "./tool/json-viewer/json-viewer.component";
import { EmiCalculatorComponent } from "./tool/emi-calculator/emi-calculator.component";
import { IndianCurrencyFormatterPipe } from "./pip/indian-currency-formatter.pipe";
import { RentReceiptComponent } from "./tool/rent-receipt/rent-receipt.component";
import { MathbuzzComponent } from "./tool/mathbuzz/mathbuzz.component";
import { MultiQrGeneratorComponent } from "./tool/qr-code/multi-qr-generator/multi-qr-generator.component";

@NgModule({
    declarations: [MultiQrGeneratorComponent, MathbuzzComponent, RentReceiptComponent, IndianCurrencyFormatterPipe, EmiCalculatorComponent, JsonViewerComponent, QrCodeGeneratorComponent, UuidComponent, GenerateUuidComponent, AppComponent, FooterComponent, WordCountComponent, ListCompareComponent, SortComponent],
    bootstrap: [AppComponent],
    imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    ActionBarComponent,
    RouterOutlet,
    RouterLink,
    QRCodeModule
]
    
})
export class AppModule {}