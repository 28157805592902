
<div class="mt-1"></div>
<div class="card ">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <!-- Action Button -->
      <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidePanel" aria-controls="sidePanel">
        <span class="d-none d-md-inline">Side Menu </span> <!-- Visible on medium and larger screens -->
        <i class="bi bi-list"></i> <!-- Icon visible on all screen sizes -->
      </button>

      <!-- Brand -->
      <a class="navbar-brand" href="#">
        <span class="d-lg-inline">maTools </span> <!-- Visible on large screens -->
        <i class="bi bi-gear-fill"></i><!-- Icon visible on all screen sizes -->
      </a>
    </div>
  </nav>
</div>

  <!-- Side Panel (Offcanvas) -->
  <div class="offcanvas offcanvas-start" tabindex="-1" id="sidePanel" aria-labelledby="sidePanelLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="sidePanelLabel">maTools</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">

      <a href="/compare-list" class="list-group-item list-group-item-action">
        <i class="bi bi-list-check"></i> List Compare
      </a>
      <a href="/word-count" routerLinkActive="selected"  class="list-group-item list-group-item-action">
        <i class="bi bi-file-word"></i> Word Count
      </a>
      <a href="/generate-uuid" routerLinkActive="selected"  class="list-group-item list-group-item-action">
        <i class="bi bi-code-slash"></i> Generate UUID
      </a>
      <a href="/generate-qr-code" routerLinkActive="selected"  class="list-group-item list-group-item-action">
        <i class="bi bi-qr-code"></i> Generate QR-Code
      </a>
      <a href="/generate-multi-qr-code" routerLinkActive="selected"  class="list-group-item list-group-item-action">
        <i class="bi bi-qr-code"></i> Generate Multi QR-Code
      </a>
      <a href="/generate-rent-receipt" routerLinkActive="selected"  class="list-group-item list-group-item-action">
        <i class="bi bi-qr-code"></i> Generator Rent Receipt
      </a>
      <a href="/emi-calculator" routerLinkActive="selected"  class="list-group-item list-group-item-action">
        <i class="bi bi-calculator"></i> EMI Calculator
      </a>
      <a href="/math-buzz" routerLinkActive="selected"  class="list-group-item list-group-item-action">
        <i class="bi bi-123"></i> MathBuzz
      </a>

      <button class="mt-4 btn btn-secondary" data-bs-dismiss="offcanvas">Close</button>
    </div>
  </div>
